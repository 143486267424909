
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        









.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: inherit;
}

.multiselect__select {
  height: $input-height;
}

.multiselect__tags {
  font-size: inherit;
  min-height: $input-height;
  padding-top: rem(8);
  padding-left: $input-padding-x;
  border-color: $input-border-color;
  border-radius: $input-border-radius;

  .is-invalid & {
    border-color: $form-feedback-invalid-color;
  }
}
